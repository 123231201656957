import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../pages/styles.css';
import { Row, Col } from 'react-bootstrap'
import Layout from '../components/layout.js'

import republiqmp4 from '../images/republiq.mp4'
import fotamp4 from '../images/fota.mp4'
import backButton from '../images/backArrow.svg'

//for categorizing media orientation, vertical/square don't need to be defined
const UNDETERMINED = 0
const HORIZONTAL = 1

var videos = {
  republiqmp4: republiqmp4,
  fotamp4: fotamp4,
}

class ProjectPage extends Component {
  constructor(props) {
    super(props)

    //need to add conditional, if there is no location.state
    let footerActive;
    if (this.props.location.state == null) {
      footerActive = true;
    } else {
      footerActive = this.props.location.state.footerActive;
    }

    this.state = {
      footerActive: footerActive,
      windowWidth: 0,
    }

    this.updateDimensions = this.updateDimensions.bind(this);
    this.toggleFooter = this.toggleFooter.bind(this);
  }

  //all of this width shit is ripped from index.js -> maybe i can find a more elegant solution later?
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    this.setState({ windowWidth });
    if (windowWidth < 960) {
      this.setState({ footerActive: false });
    }
  }

  toggleFooter() {
    const currentState = this.state.footerActive;
    this.setState({ footerActive: !currentState})
  }

  render() {
    const mediumScreen = this.state.windowWidth < 1167
    const smallScreen = this.state.windowWidth < 960

    const data = this.props.data
    const projectData = data.allContentfulProject.edges[0].node

    //same function as in index.js, but without pressability
    //only for FIRST photo
    function videoOrImg(props) {
      const title = props.title.toLowerCase()
      const videoTitle = `${title}mp4`
      if (props.animated) {
        return (
          <div>
            <video preload="auto" autoPlay muted loop playsInline
              className='mainPicFront'
              style={{width: '31vh', height: '100%'}}
            >
              <source src={videos[videoTitle]} type="video/mp4" />
            </video>
          </div>
        )
      } else {
        return (
          <div>
            <GatsbyImage
              image={getImage(props.mainPicture)}
              alt={props.title}
              loading="eager"
              className='mainPicFront'
              placeholder="tracedSVG"
            />
          </div>
        )
      }
    }

    // Generating Content for Project Details
    function ProjDetail(props) {
      //if field loads "empty", don't return any JSX element
      if (props.content !== "empty") {
        return (
          <div style={{marginBottom: '10px'}}>
            <p style={{marginBottom: '1px', paddingBottom: 0 }}>
              <span className ="projDetailCateg">
                {props.category}:
              </span>
               &thinsp; {props.content}
            </p>
          </div>
        )
      } else {
        return null
      }
    }

    function ProjButton(props) {
      if (props.button && props.link) {
        return (
          <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
            <a href={props.link}
            target="_blank"
              className='footerLink2'
              rel="noreferrer"
            >
              <button className='projLinkBack'>
                <div className="projLinkFront">
                  <h6 style={{
                    marginBottom: 0,
                    padding: '15px', paddingRight: '20px', paddingLeft: '20px'
                  }}>
                    {props.button}
                  </h6>
                </div>
              </button>
            </a>
          </div>
        )
      } else {
        return null
      }
    }

    function ProjText(props) {
      var projectData = props.projectData;
      return (
      <div>
        <h4 style={{marginBottom: '25px'}}>{projectData.projectType}</h4>
        <h2>{projectData.title}</h2>
        <p>
          {projectData.description.description}
        </p>
        
        {projectData.descriptionPrgrph2 ? <p>{projectData.descriptionPrgrph2.descriptionPrgrph2}</p> : <></>}
        
        {/* spacing + dividers */}
        <div style={{borderBottom: '1px rgba(0,0,0,0.25) solid', marginRight: '20px'}}/>
        <div style={{marginBottom: '20px'}}/>

        {/* project details */}
        {projectData.projectDate ? <ProjDetail category="Date" content={projectData.projectDate}/> : <></>}
        {projectData.projectLocation ? <ProjDetail category="Location" content={projectData.projectLocation}/> : <></>}
        {projectData.projectRole ? <ProjDetail category="Role" content={projectData.projectRole}/> : <></>}
        {projectData.projectSkills ? <ProjDetail category="Tools" content={projectData.projectSkills}/> : <></>}
        {projectData.projectInstruments ? <ProjDetail category="Instruments" content={projectData.projectInstruments}/> : <></>}
        {projectData.projectMedium ? <ProjDetail category="Medium" content={projectData.projectMedium}/> : <></>}
        {projectData.projectResult ? <ProjDetail category="Result" content={projectData.projectResult}/> : <></>}
        

        {/* spacing + dividers */}
        <div style={{marginTop: '20px'}}/>
        {(projectData.actionLinkButton) ? <div style={{borderBottom: '1px rgba(0,0,0,0.25) solid', marginRight: '20px'}}/> : <></>}
        <div style={{marginBottom: '20px'}}/>

        {/* link button */}
        {projectData.actionLinkButton ? <ProjButton button={projectData.actionLinkButton[0]} link={projectData.actionLinks[0]}/> : <></>}
        {projectData.actionLinkButton ? <ProjButton button={projectData.actionLinkButton[1]} link={projectData.actionLinks[1]}/> : <></>}
        {projectData.actionLinkButton ? <ProjButton button={projectData.actionLinkButton[2]} link={projectData.actionLinks[2]}/> : <></>}
        

      </div>
      )
    }

    //Loading all project media into rows to display, slightly different from index.js,
    const rows = []
    var i = 0
    var orient_one, orient_two = 0

    if (projectData.otherPictures) {
      while (i < projectData.otherPictures.length) {
        //setting the orientation of current and next media object
        orient_one = UNDETERMINED
        orient_two = UNDETERMINED

        if (projectData.otherPictures[i].gatsbyImageData.width/
          projectData.otherPictures[i].gatsbyImageData.height > 1) {
          orient_one = HORIZONTAL
        }
        if (projectData.otherPictures[i+1]) {
          if (
            projectData.otherPictures[i+1].gatsbyImageData.width/
            projectData.otherPictures[i+1].gatsbyImageData.height > 1){
              orient_two = HORIZONTAL
            }
        }

        if (orient_one === HORIZONTAL) {
          rows.push(projectData.otherPictures.slice(i, i+1))
          i++
          if (orient_two === HORIZONTAL) {
            rows.push(projectData.otherPictures.slice(i+1, i+2))
            i++
          }
        } else {
          rows.push(projectData.otherPictures.slice(i, i+2))
          i+=2
        }
      }
    }

    
// BELOW: COMPONENTS ARE ALL RENDERED IN THE "RETURN"

    return (
      <Layout isThin={smallScreen} footerToggled={false} isProj={true} footerActive={this.state.footerActive} toggleFooter={this.toggleFooter.bind(this)}>

      {/* A large conditional ternary operator - renders components depending on desktop vs. mobile. */}
      {smallScreen ?
        //FOR MOBILE ------------------------------------------------------------------------------
        //    In mobile, the text and the photos are interweaved together.

        <div style={{marginRight: '30px', marginLeft: '30px'}}>
          {/* Back Button */}
          <div style={{paddingTop: '20px', paddingBottom: '25px', top: '40px', position: 'sticky', zIndex: 7}}>
            <Link to='/' state={{footerActive: this.state.footerActive}}>
              <button className='backButtonBack'>
                <div className="backButtonFront">
                  <img src={backButton} style={{width: '35%', height: 'auto'}}
                    alt={'backButton'}
                  />
                </div>
              </button>
            </Link>
          </div>

          {/* Project Information */}
          <div style={{padding: '20px'}}/>

          <ProjText projectData={projectData}/>

          <div style={{marginTop: '40px'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              {videoOrImg(projectData)}
            </div>

            {rows.map((row, index) => {
              return (
                <Row className={'rowSmall'} key={`row${index}`}>
                  {row.map(picturesData => {
                    const img = getImage(picturesData)
                    return (
                      <Col sm="auto" style={{flex: img.width/img.height}} key={picturesData.title}>
                        <div>
                          <GatsbyImage
                            image={getImage(picturesData)}
                            alt={picturesData.title}
                            loading="eager"
                            className='mainPicFront'
                            placeholder="tracedSVG"
                          />
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              )
            })}

          </div>
          
          <div style={{height: '125px'}} />
        </div>

        :
        //FOR DESKTOP --------------------------------------------------------------------------------------------------------------
        <div style={{display:'flex', margin: 0, paddingLeft: '100px'}}>
        {/*PHOTOS ----------------------------------------------------------*/}
          <div style={{overflowY: 'scroll', height: '100vh', width: '100vw', borderRight: '0.5px solid'}}>
              {/* back button */}
              <div style={{paddingTop: '40px', paddingBottom: '25px', top: '40px', position: 'sticky', zIndex: 7}}>
                <Link to='/' style={mediumScreen ? {paddingLeft: '6px'} : {}} state={{footerActive: this.state.footerActive}}>
                  <button className='backButtonBack'>
                    <div className="backButtonFront">
                      <img src={backButton} style={{width: '35%', height: 'auto'}}
                        alt={'backButton'}
                      />
                    </div>
                  </button>
                </Link>
              </div>

              <div style={{marginTop: '40px', marginRight: '80px'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  {videoOrImg(projectData)}
                </div>

                {rows.map((row, index) => {
                  return (
                    <Row className={'rowBig'} key={`row${index}`}>
                      {row.map(picturesData => {
                        const img = getImage(picturesData)
                        return (
                          <Col sm="auto" style={{flex: img.width/img.height}} key={picturesData.title}>
                            <div>
                              <GatsbyImage
                                image={getImage(picturesData)}
                                alt={picturesData.title}
                                loading="eager"
                                className='mainPicFront'
                                placeholder="tracedSVG"
                              />
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                  )
                })}

              </div>
              <div style={{height: '125px'}} />
          </div>

          {/*TEXT ----------------------------------------------------------*/}
          <div className={'projRightCol'}>

            <div style={{marginLeft: '40px', marginRight: '50px'}} className={'vertical-center'}>
              <ProjText projectData={projectData}/>
            </div>

            <div style={{marginBottom: '350px '}}/>
            
          </div>
        </div>
      }
      {/*end of ternary operator*/}
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    allContentfulProject(filter: {title: {eq: $slug}}) {
      edges {
        node {
          actionLinks
          actionLinkButton
          id
          title
          hoverTitleGif {
            file {
              url
            }
            title
          }
          animated
          footnoteHeader
          footnoteDescription {
            footnoteDescription
          }
          mainPicture {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
          projectResult
          projectDate
          projectLocation
          projectRole
          projectSkills
          projectType
          projectMedium
          orientation
          otherPictures {
            title
            gatsbyImageData(placeholder: TRACED_SVG)
          }
          description {
            description
          }
          descriptionPrgrph2{
            descriptionPrgrph2
          }
        }
      }
    }
  }
`

export default ProjectPage