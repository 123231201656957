import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col } from 'react-bootstrap'
import '../pages/styles.css';
import arrow from '../images/arrow.svg'
import spinmp4 from '../images/spinhead.mp4'
import bckgBttn from '../images/bckgButton.svg'
// import longLinkArrow from '../images/longLinkArrow.svg'
import refreshButton from '../images/refreshButton.svg'


//DUDE ALBERT, NOTE ON 1/2/22 - MAKE REFRESHABLE DESCRIPTIONS OF YOURSELF. IT'LL CYCLE BETWEEN DIFFERENT DESCRIPTIONS OF YOURSELF.
class Footer extends Component {
  constructor(props) {
    super(props)
   
    this.state = {
      showRefreshButton: false,
      windowWidth: undefined
    }
  }

  bckgButtonClicked() {
    const showState = this.state.showRefreshButton;
    this.setState(state => ({ showRefreshButton: !showState}))
  }

  showBckgButton() {
    if (!this.state.showRefreshButton) {
      return (
        <button
          className='bckgButton'
          onClick={() => {
            this.props.randomizeGif()
            this.bckgButtonClicked()
          }}
          style={{ marginLeft:'auto'}}
        >
            <img src={bckgBttn} alt='backgroundButton'
              style={{
                marginLeft:'12px',
                transform: `${this.props.footerActive ? 'none' : 'rotate(180deg)'}`
              }}
            />
        </button>
      )
    } else {
      return (
        <button
          className='bckgButton'
          onClick={() => {
            this.props.noGif()
            this.bckgButtonClicked()
          }}
          style={{ marginLeft:'auto'}}
        >
            <img src={refreshButton} alt='refreshButton'
              style={{
                marginLeft:'12px',
                transform: `${this.props.footerActive ? 'none' : 'rotate(180deg)'}`
              }}
            />
        </button>
      )
    }
  }

  render() {
    return (
      <div className='footer'>
        <Row className='footerNav align-items-center'>
          <button
            className='arrowButton'
            onClick={this.props.toggleFooter}
          >
            <img src={arrow} alt='arrow'
              style={{
                marginLeft:'12px',
                transform: `${this.props.footerActive ? 'none' : 'rotate(180deg)'}`
              }}
            />
          </button>
          <h3 style={{paddingBottom: 0, marginBottom: 0, paddingLeft: '20px'}}>
            ABOUT ME
          </h3>
          {this.showBckgButton()}
        </Row>
        <Row
          style={{paddingBottom:'30px'}}
          className=
            //sorry future albert, this is a really shitty nested conditional ternary operator
            //it first checks if the footer should be toggled or not, then
            //checks the screen width to adjust for smaller screens
            {`${this.props.footerActive ? `${this.props.isThin ? 'smallFooterContent' : 'footerContent'}` : 'footerContentHide'} align-items-center`}
          >
          <Col xs={this.props.isThin ? 12 : 10} style={{alignItems: 'center'}}>
            <p style={{
              paddingTop: '45px', paddingBottom:'5px',
              fontSize: this.props.isThin ? '18px' : '20px'
            }}>
            I’m Albert B. Zhang and I'm a composer. Currently a designer at Garnish Studios, and will be studying for a Master's in Digital Musics at Dartmouth. I have co-founded two startups that have interviewed at Y Combinator, and most recently graduated from Harvard in 2023 with a degree in Art and CS.  This website is perpetually under construction.
              &thinsp; &thinsp;
              <a href='mailto:albertzhang@college.harvard.edu' target="_blank"
                className='footerLink2'
                rel="noreferrer"
              >
              EMAIL
                <span className='linkSlash'>
                &nbsp; / &nbsp;
                </span>
              </a>
              <a href='https://www.linkedin.com/in/albszhang/' target="_blank"
                className='footerLink2'
                rel="noreferrer"

              >
              LINKEDIN
                <span className='linkSlash'>
                &nbsp; / &nbsp;
                </span>
              </a>
              <a href='https://albertzhang.medium.com/' target="_blank"
                className='footerLink2'
                rel="noreferrer"
              >
              MEDIUM
              </a>
            </p>
          </Col>

          {/* conditionally rendering video, given screen size*/}
          {this.props.isThin ? undefined :
            <Col style={{alignItems: 'center', flex: 1}}>
              <a href="https://www.youtube.com/watch?v=Rcqk06yNmdU" target="_blank" rel="noreferrer">
              <video preload="auto" autoPlay muted loop
                style={{width: '100%', height: '100%', maxHeight: '200px'}}>
                <source src={spinmp4} type="video/mp4"/>
              </video>
              </a>
            </Col>
          }
        </Row>

        {/* <p>{footnote.footerContent.raw}</p> */}
      </div>
    )
  }
}

export default Footer
