import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../pages/styles.css'
import { Container } from 'react-bootstrap'
import Footer from './footer.js'

class Layout extends Component {
  constructor(props) {
    super(props)
    this.randomizeGif = this.randomizeGif.bind(this)
    this.noGif = this.noGif.bind(this)
    this.state = { bckgURL: 'none'}
  }

//maybe to make it last throughout, it needs to happen an even higher level
  randomizeGif() {
    const list = [
      'https://media.giphy.com/media/l3vRfDn9ca5PVkHv2/giphy.gif',
      // 'https://media.giphy.com/media/3ohs7KS8nMsd2QPUTC/giphy.gif',
      'https://media.giphy.com/media/5xaOcLDEsUQjsBSjFMk/giphy.gif',
      'https://media.giphy.com/media/TlK63EChAGgxq7kTcFW/giphy.gif',
      'https://media.giphy.com/media/TlK63ETH1WVZm79Phni/giphy.gif',
      'https://media.giphy.com/media/TlK63EVRw8pOCAJTeI8/giphy.gif',
      'https://media.giphy.com/media/2rkHP9sohzTzO/giphy.gif',
      'https://media.giphy.com/media/l41lLaE950sv05uPS/giphy.gif',
      // 'https://media.giphy.com/media/3oxRmFNIGZfqJesTMA/giphy.gif',
      // 'https://media.giphy.com/media/QBd31KsDkHD0QMCQFY/giphy.gif',
      // 'https://media.giphy.com/media/3o7WTrgWULPLv2OtYk/giphy.gif', zack galifinakis

      //'https://media.giphy.com/media/F3LuIoVXgKAxHS3zFv/giphy.gif'
      // 'https://media.giphy.com/media/3o6QL1OGg8MTnXychO/giphy.gif',
      // 'https://media.giphy.com/media/yNPzi5t53UxKsLvMoU/giphy.gif',
      // 'https://media.giphy.com/media/26xBCkm84LVDYznBC/giphy.gif',
      // 'https://media.giphy.com/media/MCj3a9oGpxhJiEQ406/giphy.gif',

    ]

    // const archive = [
      // 'https://media.giphy.com/media/4Tgw0c0D2NUGAHS5zb/giphy.gif',
      // 'https://media.giphy.com/media/BlcWQ9L2VfOFO/giphy.gif',
      // 'https://media.giphy.com/media/l2JhHKpKbwQELFOmY/giphy.gif',
      // 'https://media.giphy.com/media/5SxdtXRaHYoG0QFa7P/giphy.gif',
      // 'https://media.giphy.com/media/3o7aCVTfelG4XSbv3y/giphy.gif',
      // 'https://media.giphy.com/media/xT0xeEcwa0jAcsFE7S/giphy.gif',
      //'https://media.giphy.com/media/3o85xxTkuozWI4Zdgk/giphy.gif',
    // ]

    const selection = list[Math.floor(Math.random() * list.length)]
    this.setState(state => ({ bckgURL: selection}))
  }

  noGif() {
    this.setState(state => ({ bckgURL: 'none' }))
  }


  render() {
    return(
      <div>
        <Container
          fluid
          className={
            //Nested conditional ternary operator - if it's a project, or homepage (small or big container)
            this.props.isProj ? 'projContainer' : this.props.isThin ? 'smallContainer' : 'bigContainer'
          }
          style={{
            backgroundColor: this.props.backColor,
            backgroundImage: `url(${this.state.bckgURL})`,
          }}
        >
          {this.props.children}
        </Container>
        
        
        <Footer
          toggleFooter={this.props.toggleFooter}
          randomizeGif={this.randomizeGif}
          noGif={this.noGif}
          isThin={this.props.isThin}
          footerToggled={this.props.footerToggled}
          footerActive={this.props.footerActive}
        />
      </div>
    )
  }
}

export default Layout
